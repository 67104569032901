const ANCHORS = document.querySelectorAll('a[href^="#"]')

ANCHORS.forEach(element =>
	element.addEventListener('click', e => {
		e.preventDefault()

		const ELEMENT = document.querySelector(e.target.attributes.href.value)

		setTimeout(() => {
			ELEMENT.scrollIntoView({ behavior: 'smooth' })
		}, 500)
	})
)
