import './smoothScroll.js'
import './cookies.js'

const DOCUMENT_ROOT = document.querySelector('html')
const MENU_TOGGLER = document.querySelector('.menu-toggler')
const MENU = document.querySelector('.menu')

const SWITCH_TABINDEXING = selector => {
	const ELEMENTS = document.querySelectorAll(selector)
	ELEMENTS.forEach(e => (e.tabIndex === 1 ? (e.tabIndex = -1) : (e.tabIndex = 1)))
}

const TOGGLE_MENU = () => {
	DOCUMENT_ROOT.classList.toggle('menu-active')
	SWITCH_TABINDEXING('.menu a')
}

MENU_TOGGLER.addEventListener('click', TOGGLE_MENU)
MENU.addEventListener('click', e => {
	if (e.target.tagName === 'A') TOGGLE_MENU()
})
