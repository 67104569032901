const COOKIE_ALERT = document.querySelector('.cookies')
const COOKIE_BUTTON = document.querySelector('.cookies-button')

const switchCookie = () => {
	COOKIE_ALERT.classList.add('hidden')
	localStorage.setItem('cookies', '1')
}

if (!localStorage.getItem('cookies')) {
	COOKIE_ALERT.classList.remove('hidden')
}

COOKIE_BUTTON.addEventListener('click', switchCookie)
